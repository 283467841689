import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useAuth } from "../AuthContext";

const Footer = () => {
	const navigate = useNavigate();
	const auth = getAuth();
	const { user } = useAuth();
	const [isExpanded, setIsExpanded] = useState(false);

	const handleSignOut = async () => {
		if (window.confirm("Are you sure you want to log out?")) {
			signOut(auth)
				.then(() => {
					navigate("/logout"); // Replace with your desired logout redirect
				})
				.catch((error) => {
					console.error("Error signing out:", error);
				});
		}
	};

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};
	return (
		/*Showcase Section*/
		<div>
			<div
				className={`showcase ${isExpanded ? "expanded" : ""}`}
				onClick={toggleExpand}
			>
				<div className="container">
					<div className="showcase__header">
						<div className="row">
							<div className="container">
								<div
									className={`showcase__heading ${
										isExpanded ? "expanded" : ""
									}`}
								>
									Jones UMC Energy Conservation Connection
								</div>
							</div>
						</div>
						<div className={`showcase__main ${isExpanded ? "expanded" : ""}`}>
							<div className="row2">
								<div className="container">
									<div className="showcase__subhead">
										We are dedicated to community outreach.
									</div>
									<div className="showcase__body">
										<p>
											JECC is committed to working with its community to bring
											necessary resources to ensure better stewardship of the
											environment. We want to be a hub of resources for the
											community and serve as a model of energy and financial
											stewardship for the homes and businesses of congregation
											members.
										</p>
									</div>
								</div>
								<div className="container">
									<div className="showcase__image">
										<a href="https://jonesumcjecc.org">
											<img
												className="img-responsive"
												alt="Jones UMC Energy Conservation Connection"
												src="images/logo/jecc-logo.svg"
												style={{ height: "20rem" }}
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*End Showcase section	 Footer area */}
			<div className="footer-area-container">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="footer-widget widget_nav_menu">
								<h4 className="footer-widget-title">Contact Us</h4>

								<div>
									<i className="ion-ios-location-outline"></i>
									<a href="https://www.google.com/maps/place/4625+G+St+SE,+Washington,+DC+20019/@38.8805777,-76.9373085,803m/data=!3m2!1e3!4b1!4m6!3m5!1s0x89b7b8dbed7b1375:0xeec3fa47ac14809a!8m2!3d38.8805777!4d-76.9347336!16s%2Fg%2F11c1y4vd4c!5m1!1e3?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D">
										4625 G St SE, Washington, DC 20019
									</a>
									<br />
									<i className="ion-ios-telephone-outline"></i>
									<a href="tel:+1202-583-7116">(202) 583-7117</a>
									<br />
									<i className="ion-ios-email-outline"></i>
									<a href="info@jonesmemorialumc.org">
										{" "}
										info@jonesmemorialumc.org
									</a>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="footer-widget widget_nav_menu">
								<h4 className="footer-widget-title">
									<NavLink to="/about">About Us</NavLink>
								</h4>
								<ul>
									<li className="menu-item">
										<NavLink to="/history">
											<a>History</a>
										</NavLink>
									</li>
									<li className="menu-item">
										<NavLink to="/leadership">
											<a>Leadership</a>
										</NavLink>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-3">
							{user ? (
								<div className="footer-widget widget_nav_menu">
									<h4 className="footer-widget-title">
										<NavLink to="/dashboard">Dashboard</NavLink>
									</h4>

									<ul>
										<li>
											<NavLink to="/events">
												<a>Events</a>
											</NavLink>
										</li>
										<li>
											<NavLink to="/articleUpload">
												<a>Articles</a>
											</NavLink>
										</li>
										<li>
											<NavLink to="/photoUpload">
												<a>Photos</a>
											</NavLink>
										</li>
										<li>
											<a onClick={handleSignOut}>Log Out</a>
										</li>
									</ul>
								</div>
							) : (
								<div className="footer-widget widget_nav_menu">
									<NavLink to="/login">
										<a>Log In</a>
									</NavLink>
								</div>
							)}
						</div>
					</div>
					<br></br>
					<div className="row2">
						{/* Social Networks section */}

						<a href="https://www.facebook.com/profile.php?id=61561299818311">
							<p className="icon ion-social-facebook"></p>
						</a>
						<a href="https://www.instagram.com/jonesmemorialumcdc/">
							<p className="icon ion-social-instagram"></p>
						</a>
						<a>
							<p className="icon ion-social-youtube"></p>
						</a>

						{/* End Social Networks section */}
					</div>
				</div>
			</div>
			{/* End Footer area*/}
			{/*	 Copyright */}
			<div className="footer-holder">
				<div className="container">
					<footer className="site-footer">
						<div className="row">
							<div className="col-md-6">
								<p>
									© 2024 | All Rights Reserved Jones Memorial United Methodist
									Church
								</p>
							</div>
						</div>
					</footer>
				</div>
			</div>
		</div>
	);
};
export default Footer;
