import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { useAuth } from "../AuthContext";

function Gallery() {
	const [images, setImages] = useState([]);
	const [isFullImgOpen, setIsFullImgOpen] = useState(false);
	const [fullImgSrc, setFullImgSrc] = useState("");
	const { user } = useAuth();

	const fetchImages = async () => {
		try {
			const querySnapshot = await getDocs(collection(db, "photos")); // Adjust collection name if needed
			const fetchedImages = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				src: doc.data().imageUrl || "default-image.jpg", // Add default image URL
				alt: doc.data().altText || "Image", // Add default alt text
			}));
			setImages(fetchedImages);
		} catch (error) {
			console.error("Error fetching images:", error);
		}
	};

	const openFullImg = (src) => {
		setIsFullImgOpen(true);
		setFullImgSrc(src);
	};

	const closeFullImg = () => {
		setIsFullImgOpen(false);
		setFullImgSrc(""); // Reset state for cleanliness
	};

	const handleDelete = async (id) => {
		if (window.confirm("Are you sure you want to delete this photo?")) {
			try {
				await deleteDoc(doc(db, "photos", id));
				// Update the state directly to remove the deleted article
				setImages(images.filter((image) => image.id !== id));
				console.log("Photo deleted successfully");
			} catch (error) {
				console.error("Error deleting article:", error);
				alert("Error deleting photo. Please try again later.");
			}
		}
	};
	useEffect(() => {
		fetchImages();
	}, []);

	return (
		<div className="box-layout">
			<div className="container-about">
				<h1 style={{ textAlign: "center" }}>Photo Gallery</h1>
				<div className="photo-gallery">
					{images.map((image) => (
						<div key={images.id} ckassName="image-container">
							<img
								key={image.id} // Use unique ID from Firestore
								src={image.src}
								alt={image.alt}
								onClick={() => openFullImg(image.src)}
							/>
							{user && (
								<button
									className="button"
									onClick={() => handleDelete(image.id)}
								>
									Delete
								</button>
							)}
						</div>
					))}
				</div>

				{isFullImgOpen && (
					<div id="fullImgBox" className="full-img">
						<img
							id="fullImg"
							src={fullImgSrc}
							alt={images.alt}
							onClick={closeFullImg}
						/>
						<span onClick={closeFullImg}>X</span>
					</div>
				)}
			</div>
		</div>
	);
}

export default Gallery;
