import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import { collection, getDocs, orderBy } from "firebase/firestore";

import CustomSlider from "../components/CustomSlider";
import formatTimestamp from "../components/Timestamp";

import { Link, NavLink } from "react-router-dom";

function Home() {
	const [sliderItems, setSliderItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [articles, setArticles] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const querySnapshot = await getDocs(collection(db, "events"));
				const items = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					imageUrl: doc.data().imageUrl,
					title: doc.data().title,
					subtitle: doc.data().subtitle,
					date: doc.data().date,
				}));
				setSliderItems(items);
				setIsLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
				setIsLoading(false);
			}
		};

		const fetchArticles = async () => {
			try {
				const querySnapshot = await getDocs(
					collection(db, "articles"),
					orderBy("createdAt", "desc")
				);
				const articlesData = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					title: doc.data().title,
					content: doc.data().content,
					imageUrl: doc.data().imageUrl,
					createdAt: doc.data().createdAt,
				}));
				setArticles(articlesData);
			} catch (error) {
				console.error("Error fetching articles:", error);
			}
		};

		fetchArticles();

		fetchData();
	}, []);

	return (
		<div className="box-layout">
			{isLoading && (
				<div className="page-loader">
					<div className="loader">
						<span className="cssload-loading"></span>
					</div>
				</div>
			)}
			<div className="slider-wrapper">
				<CustomSlider images={sliderItems} />
			</div>
			{/* End Slider 	 Main Section */}
			<div className="container-about">
				<h2 style={{ textAlign: "center" }}>Free Food For Families</h2>
				<div className="title-un-icon"></div>
				<p className="title-un-des">
					Register here for the Jones Memorial Free Food For Families Initiative.
				</p>
				<NavLink to="/food_registration">
					<p
						style={{
							display: "block",
							margin: "0 30%",
						}}
						className="slide-button"
					>
						Register Here
					</p>
				</NavLink>
			</div>
			<div className="container-about">
				<div className="container">
					<div className="row">
						<h3 className="title-un">WHO WE ARE</h3>
						<div className="title-un-icon"></div>
						<p className="title-un-des">
							Jones Memorial United Methodist Church is a family-focused
							community of faith located in the Benning Heights community of
							southeast Washington, DC. For over 160 years, the mission of Jones
							Memorial has been to share the Gospel of Jesus Christ and make
							disciples for the transformation of the world.
						</p>
					</div>
				</div>

				{/* Blog Section */}

				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h3 className="title-un">
								Happening at Jones Memorial UMC &nbsp;
								<NavLink to="/newsPage">
									<p className="button">See More Posts</p>
								</NavLink>
							</h3>

							<p className="title-un-des">
								Follow this section to see past events from Jones UMC
							</p>
							<div className="blog-posts-g">
								{articles
									.slice(-3)
									.reverse()
									.map((article) => (
										<li key={article.id}>
											<div className="post-img">
												<p>
													<Link to={`/newsPage/${article.id}`}>
														<img src={article.imageUrl} alt={article.title} />
													</Link>
												</p>
											</div>
											<h4>
												<Link to={`/newsPage/${article.id}`}>
													{article.title}
												</Link>
											</h4>
											<p>{formatTimestamp(article.createdAt)}</p>
											<p className="post-content">{article.content}</p>
										</li>
									))}
							</div>
						</div>
					</div>
				</div>
				{/*Contact Section*/}

				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h3 className="title-un">Want us to contact you?</h3>
							<div className="title-un-icon"></div>
							<p className="title-un-des">
								leave us your email and we will get back to you as soon as
								possible.
							</p>
							<div className="newsletter-container wow fadeInUp">
								<form
									action="https://formsubmit.co/info@jonesmemorialumc.org"
									method="POST"
								>
									<input
										type="hidden"
										name="_subject"
										value="New Email Submission"
									/>
									<input
										type="hidden"
										name="_next"
										value="https://jonesumc.org/thank_you.html"
									/>
									<input type="hidden" name="_replyto" />
									<input
										className="newsletter-field"
										type="email"
										required=""
										name="email"
										placeholder="email address"
									/>{" "}
									&nbsp;
									<button type="submit" className="button green-button">
										SEND
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*End Contact Section*/}
		</div>
	);
}

export default Home;
